<template>
  <b-container fluid class="p-5">
    <b-card align="center">
      <b-card-body class="p-3">
        <div v-if="selectedEmojis.length > 0">
          <div style="font-size: 100px;">
            {{ selectedEmojis[0] }}
          </div>
          <div style="font-size: 50px;">
            {{ selectedEmojis.join(' ') }}
          </div>
        </div>
        <div class="fs-5 fw-semibold" v-else>
          Clique sur tes Emojis préférés ma puce ! ❤️
        </div>
      </b-card-body>
    </b-card>
  </b-container>

  <footer class="position-absolute bottom-0 start-50 translate-middle-x mb-5 w-100">
    <EmojiPicker @select="onSelectEmoji"
      :native="true"
      :hide-search="true"
      :disable-skin-tones="true"
      :hide-group-icons="true"
      :hide-group-names="true"
      :display-recent="false" />
  </footer>
</template>

<script setup>
import { ref } from 'vue';
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'

const selectedEmojis = ref([]);

const onSelectEmoji = emoji => {
  selectedEmojis.value.unshift(emoji.i);
}
</script>

<style>
body {
  background-color: #f8f9fa !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v3-emoji-picker {
  width: 85%;
  height: 300px;
  box-shadow: none;
  border: var(--bs-border-width) solid var(--bs-border-color-translucent);
}

/* .v3-emoji-picker .v3-body .v3-body-inner .v3-group .v3-emojis {
  font-size: 30rem !important;
} */

.v3-emoji-picker .v3-body .v3-body-inner .v3-group .v3-emojis button {
  font-size: 50px;
  flex-basis: 20%;
  max-width: 20%;
}

.v3-footer {
  display: none !important;
}
</style>
